<template>
  <div class="disease-input-form">
    <div v-if="editTarget === ''">
      <select-box-text-form
        :label="'傷病分類名'"
        :maxlength="20"
        :placeholder="'20文字まで'"
        :requiredFlg="true"
        :selectData="diseaseClassesSelectDataZero"
        :selectStyles="{ width: '200px' }"
        :selectValue="data.diseaseClass.id"
        :textDisabled="Boolean(data.diseaseClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="data.diseaseClass.name"
        @select-input="$emit('select-class', $event)"
        @text-input="handleInput($event, 'diseaseClass', 'name')"
      />
      <text-input-form
        class="margin"
        :value="data.disease.name"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :styles="{ width: '350px' }"
        @input="handleInput($event, 'disease', 'name')"
        >傷病名</text-input-form
      >
    </div>
    <div v-if="editTarget === 'disease-classes'">
      <text-input-form
        class="margin"
        :value="data.diseaseClass.name"
        :maxlength="20"
        :placeholder="'20文字まで'"
        :requiredFlg="true"
        :styles="{ width: '350px' }"
        @input="handleInput($event, 'diseaseClass', 'name')"
        >傷病分類名</text-input-form
      >
      <select-box-form
        class="margin"
        :value="data.diseaseClass.showFlg"
        :selectData="displaySelectData"
        @input="handleInput($event, 'diseaseClass', 'showFlg')"
        >表示設定</select-box-form
      >
    </div>
    <div v-if="editTarget === 'diseases'">
      <div class="disease-class">
        <label>傷病分類名</label>
        <div>{{ data.diseaseClass.name }}</div>
      </div>
      <text-input-form
        class="margin"
        :value="data.disease.name"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :styles="{ width: '350px' }"
        @input="handleInput($event, 'disease', 'name')"
        >傷病名</text-input-form
      >
      <select-box-form
        class="margin"
        :value="data.disease.showFlg"
        :selectData="displaySelectData"
        @input="handleInput($event, 'disease', 'showFlg')"
        >表示設定</select-box-form
      >
    </div>
  </div>
</template>

<script>
import SelectBoxTextForm from '@/components/parts/molecules/SelectBoxTextForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'

export default {
  name: 'DiseaseInputForm',

  components: { SelectBoxTextForm, TextInputForm, SelectBoxForm },

  props: {
    data: { type: Object },
    diseaseClassesSelectDataZero: { type: Array },
    editTarget: { type: String, default: '' }
  },

  data() {
    return {
      displaySelectData: [
        { id: 1, name: '〇' },
        { id: 0, name: '✕' }
      ]
    }
  },

  methods: {
    handleInput(val, target, key) {
      this.$emit('input', { target, key, val })
    }
  }
}
</script>

<style lang="scss" scoped>
.disease-input-form {
  div {
    .margin {
      margin-top: 20px;
    }
    .disease-class {
      display: flex;
      height: 33px;
      font-size: 15px;
      label {
        width: 40%;
      }
    }
  }
}
</style>
