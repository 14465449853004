<template>
  <div class="disease-show">
    <div class="list-link">
      <base-back-link @click="goToListPage" />
    </div>
    <div class="title">傷病分類詳細</div>
    <div class="contents">
      <div class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="goToEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
      <div class="basic-items-area">
        <div class="heading">基本項目</div>
        <div class="basic-items">
          <show-data-list
            :displayKeys="displayKeys"
            :keys="keys"
            :data="displayData"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'DiseaseShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      displayKeys: ['傷病分類名', '傷病名'],
      keys: ['className', 'name'],
      popupFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getDisease: 'diseases/getDataByOriginalId',
      getDiseaseClass: 'diseaseClasses/getDataByOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayData() {
      const disease = this.getDisease(this.originalId)
      if (disease) {
        const diseaseClass = this.getDiseaseClass(
          disease.diseaseClassOriginalId
        )
        return {
          id: disease.id,
          className: diseaseClass.name,
          name: disease.name
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    goToEditPage() {
      const params = { originalId: this.originalId, target: 'diseases' }
      this.$router.push({ name: 'diseases-edit', params })
    },
    goToListPage() {
      const disease = this.getDisease(this.originalId)
      const query = { c: disease.diseaseClassOriginalId }
      this.$router.push({ name: 'diseases', query })
    },
    openDeletePopup() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.popupFlg = true
    },
    cancelDelete() {
      this.popupFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'diseases/delete',
        this.displayData.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      this.goToListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.disease-show {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .list-link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .basic-items-area {
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 20px;
      }
      > .basic-items {
        margin-top: 25px;
      }
    }
  }
}
</style>
