<template>
  <div class="disease-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">傷病分類名編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <disease-input-form
              class="input-form"
              :data="editData"
              :diseaseClassesSelectDataZero="diseaseClassesSelectDataZero"
              :editTarget="target"
              @input="handleInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToTargetPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import DiseaseInputForm from '@/components/parts/organisms/DiseaseInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'DiseaseEdit',

  components: {
    DiseaseInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    originalId: { type: Number },
    target: { type: String }
  },

  data() {
    return {
      editData: {},
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      noDataFlg: false,
      outdatedFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      diseaseClassesSelectDataZero: 'diseaseClasses/selectDataZero',
      getDiseaseClass: 'diseaseClasses/getDataByOriginalId',
      getDisease: 'diseases/getDataByOriginalId'
    })
  },

  created() {
    if (this.target === 'disease-classes') {
      const target = this.getDiseaseClass(this.originalId)
      this.editData.diseaseClass = {
        id: target.id,
        originalId: target.originalId,
        name: target.name,
        showFlg: target.showFlg
      }
    } else {
      const disease = this.getDisease(this.originalId)
      this.editData.disease = {
        id: disease.id,
        originalId: disease.originalId,
        name: disease.name,
        showFlg: disease.showFlg
      }
      const diseaseClass = this.getDiseaseClass(disease.diseaseClassOriginalId)
      this.editData.diseaseClass = {
        id: diseaseClass.id,
        originalId: diseaseClass.originalId,
        name: diseaseClass.name,
        showFlg: diseaseClass.showFlg
      }
    }
    this.mixinInputData = {
      diseaseClass: this.editData.diseaseClass,
      disease: this.editData.disease
    }
    this.mixinSetInitialData()
  },

  methods: {
    goToListPage() {
      const query = { c: this.editData.diseaseClass.originalId }
      this.$router.push({ name: 'diseases', query })
    },
    goToShowPage() {
      const params = { originalId: this.editData.disease.originalId }
      this.$router.push({ name: 'disease-show', params })
    },
    goToTargetPage() {
      this.target === 'disease-classes'
        ? this.goToListPage()
        : this.goToShowPage()
    },
    handleInput({ target, key, val }) {
      this.editData[target][key] = val
    },
    async updateData() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        `${_.camelCase(this.target)}/update`,
        this.editData
      )
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (res === 'already edited') {
          this.popupMessage =
            this.target === 'disease-classes'
              ? '編集に失敗しました。\n編集中の傷病分類は別端末で編集されました。'
              : '編集に失敗しました。\n編集中の傷病は別端末で編集されました。'
          this.outdatedFlg = true
        } else if (res === 'already deleted') {
          this.popupMessage =
            this.target === 'disease-classes'
              ? '編集に失敗しました。\n編集中の傷病分類は既に削除されています。'
              : '編集に失敗しました。\n編集中の傷病は既に削除されています。'
          this.noDataFlg = true
        } else if (res === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n傷病は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToTargetPage()
      } else if (this.outdatedFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToTargetPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.disease-edit {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
