<template>
  <div class="diseases">
    <div class="title">傷病名</div>
    <div class="content">
      <div class="area buttons" v-if="!sortModeFlg">
        <router-link :to="'diseases/new'">
          <base-button-register :text="'項目追加'" />
        </router-link>
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="area buttons" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="waitFlg"
          @click="updateOrder"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="area list">
        <slide-list
          ref="slideList"
          :bodyData="displayDiseases"
          :bottomLayer="bottomLayer"
          :sortModeFlg="sortModeFlg"
          @order-parents="orderParents"
          @order-children="orderChildren"
          @add-child="goToNewPage"
          @delete-parent="deleteDiseaseClassAnnounce"
          @edit-parent="editDiseaseClass"
          @select-child="pushToDiseaseShow"
        />
      </div>
    </div>
    <announce-popup
      v-if="popup.showFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      @close="closePopup"
      @cancel="popup.cancel"
      @decision="popup.decision"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SlideList from '@/components/parts/organisms/SlideList'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Diseases',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SlideList,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      bottomLayer: 'child',
      waitFlg: false,
      popup: {
        showFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        cancel: () => {},
        decision: () => {}
      },
      targetId: 0,
      sortModeFlg: false
    }
  },

  computed: {
    ...mapGetters({
      diseaseByClassOriginalId: 'diseases/getDataByDiseaseClassOriginalId',
      diseaseClasses: 'diseaseClasses/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getDiseaseClass: 'diseaseClasses/getDataById'
    }),
    displayDiseases() {
      return this.sortModeFlg
        ? this.mixinInputData
        : this.diseaseClasses
            .map(v => {
              const parentObj = {
                id: v.id,
                originalId: v.originalId,
                name: v.name,
                showFlg: v.showFlg,
                noChangeFlg: false
              }
              if (!this.diseaseByClassOriginalId(v.originalId)) {
                return { ...parentObj, children: [] }
              }
              const children = this.diseaseByClassOriginalId(v.originalId).map(
                w => {
                  return {
                    id: w.id,
                    originalId: w.originalId,
                    name: w.name,
                    showFlg: w.showFlg,
                    noChangeFlg: false,
                    plusFlg: false
                  }
                }
              )
              return { ...parentObj, children }
            })
            .filter(v => v !== undefined)
    }
  },

  mounted() {
    this.openAccordion()
  },

  methods: {
    setSortData() {
      this.mixinInputData = _.cloneDeep(this.displayDiseases)
      this.mixinSetInitialData()
    },
    setSortMode() {
      this.setSortData()
      this.sortModeFlg = true
    },
    cancelSortMode() {
      this.setSortData()
      this.sortModeFlg = false
    },
    orderParents(newOrderParents) {
      this.mixinInputData = newOrderParents
    },
    orderChildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex],
        'children',
        data.newOrderChildren
      )
    },
    async updateOrder() {
      this.waitFlg = true
      const idObj = { diseaseIds: [], diseaseClassIds: [] }
      this.mixinInputData.forEach(v => {
        v.children.forEach(x => {
          idObj.diseaseIds.push(x.id)
        })
        idObj.diseaseClassIds.push(v.id)
      })
      const res = await this.$store.dispatch('diseaseClasses/order', idObj)
      if (res) {
        this.mixinSetInitialData()
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '並び順を登録しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '並び順登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    goToNewPage(parentId) {
      this.$router.push({
        name: 'disease-new',
        params: { id: parentId, target: 'disease' }
      })
    },
    editDiseaseClass(parentId) {
      const diseaseClass = this.getDiseaseClass(parentId)
      this.$router.push({
        path: `/settings/diseases/disease-classes/${diseaseClass.originalId}/edit`,
        params: {
          originalId: diseaseClass.originalId,
          target: 'disease-classes'
        }
      })
    },
    deleteDiseaseClassAnnounce(parentId) {
      const diseaseClass = this.getDiseaseClass(parentId)
      this.popup = {
        showFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        message: `${diseaseClass.name}を削除してもよろしいですか？`,
        cancel: () => this.closePopup(),
        decision: () => this.delete()
      }
      this.targetId = parentId
    },
    async delete() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        `diseaseClasses/delete`,
        this.targetId
      )
      if (res === true) {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    closePopup() {
      this.popup = {
        showFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        cancel: () => {},
        decision: () => {}
      }
      this.targetId = 0
      if (this.sortModeFlg) this.cancelSortMode()
    },
    pushToDiseaseShow(childOriginalId) {
      this.$router.push({
        path: `/settings/diseases/diseases/${childOriginalId}`,
        originalId: childOriginalId
      })
    },
    openAccordion() {
      if (this.$route.query.c) {
        const refName = `parent${this.$route.query.c}`
        const parent = this.$refs.slideList.$refs[refName]
        if (parent) {
          parent[0].toggleChild()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.diseases {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .title {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
  }
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .area {
      &.buttons {
        display: flex;
        justify-content: flex-end;
        > .button {
          margin-left: 20px;
        }
      }
      &.list {
        margin-top: 26px;
      }
    }
  }
}
</style>
