<template>
  <div class="disease-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">傷病分類登録</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <disease-input-form
              class="input-form"
              :data="newData"
              :diseaseClassesSelectDataZero="classesSelectData"
              @select-class="selectClass"
              @input="handleInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import DiseaseInputForm from '@/components/parts/organisms/DiseaseInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'DiseaseNew',

  components: {
    DiseaseInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      newData: {
        diseaseClass: { id: 0, originalId: 0, name: '' },
        disease: { name: '' }
      },
      classesSelectData: [],
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      waitFlg: false,
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      diseaseClassesSelectDataZero: 'diseaseClasses/selectDataZero',
      getDiseaseClass: 'diseaseClasses/getDataById'
    })
  },

  created() {
    if (this.id) {
      const target = this.getDiseaseClass(this.id)
      this.newData.diseaseClass = {
        id: this.id,
        originalId: target.originalId,
        name: target.name
      }
    }
    this.classesSelectData = _.cloneDeep(this.diseaseClassesSelectDataZero)
    this.mixinInputData = {
      diseaseClass: this.newData.diseaseClass,
      disease: this.newData.disease
    }
    this.mixinSetInitialData()
  },

  methods: {
    goToListPage() {
      const existsClass = Boolean(this.id)
      const query = existsClass
        ? { c: this.newData.diseaseClass.originalId }
        : {}
      this.$router.push({ name: 'diseases', query })
    },
    selectClass(classId) {
      const { name = '', originalId = 0 } = this.getDiseaseClass(classId) ?? {}
      this.newData.diseaseClass.id = classId
      this.newData.diseaseClass.originalId = originalId
      this.newData.diseaseClass.name = name
    },
    handleInput({ target, key, val }) {
      this.newData[target][key] = val
    },
    async createData() {
      this.waitFlg = true
      const res = await this.$store.dispatch('diseases/create', this.newData)
      if (res === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (res === 'no data in clinic') {
          this.popupMessage =
            '登録に失敗しました。\n傷病分類は既に削除されています。'
          this.noDataFlg = true
        } else if (res === 'no diseaseClass') {
          this.popupMessage =
            '登録に失敗しました。\n傷病分類は別端末で編集または削除されました。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.disease-new {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
